/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEventLogsQueryVariables = Types.Exact<{
  period?: Types.InputMaybe<Types.DateTimePeriod>;
  customerCode?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetEventLogsQuery = { getEventLogs?: Array<{ count: number, eventName: Types.EventName }> | undefined };


export const GetEventLogsDocument = gql`
    query GetEventLogs($period: DateTimePeriod, $customerCode: String) {
  getEventLogs(period: $period, customerCode: $customerCode) {
    count
    eventName
  }
}
    `;

/**
 * __useGetEventLogsQuery__
 *
 * To run a query within a React component, call `useGetEventLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventLogsQuery({
 *   variables: {
 *      period: // value for 'period'
 *      customerCode: // value for 'customerCode'
 *   },
 * });
 */
export function useGetEventLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventLogsQuery, GetEventLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventLogsQuery, GetEventLogsQueryVariables>(GetEventLogsDocument, options);
      }
export function useGetEventLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventLogsQuery, GetEventLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventLogsQuery, GetEventLogsQueryVariables>(GetEventLogsDocument, options);
        }
export type GetEventLogsQueryHookResult = ReturnType<typeof useGetEventLogsQuery>;
export type GetEventLogsLazyQueryHookResult = ReturnType<typeof useGetEventLogsLazyQuery>;
export type GetEventLogsQueryResult = Apollo.QueryResult<GetEventLogsQuery, GetEventLogsQueryVariables>;