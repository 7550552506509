import React, { useMemo } from "react"
import { t } from "ttag"
import { TableRowData } from "@humanpredictiveintelligence/myqvt-library"

import { AutodiagStatsSection } from "../AutodiagStatsSection"
import { LedDatePicker } from "features/Led/LedDatePicker"
import {
  ContentMetric,
  CustomerContentMetric,
  LoginMetric,
  MostConsultedContentMetrics,
  PimeoMetric,
} from "features/PimeoStatistics/PimeoMetric"
import { useSession } from "features/Session"
import { MomentRange } from "models/DateTime/MomentRange"
import { AutodiagStats, EventLog } from "../models"
import * as Styles from "./PimeoStatisticsPage.styles"

import { EventName } from "models/generated"
import { useGlobalAutodiagnosticsStatisticsQuery } from "graphql/queries/generated/GlobalAutodiagnosticsStatistics"
import {
  GetEventLogsQuery,
  useGetEventLogsQuery,
} from "graphql/queries/generated/GetEventLogs"
import { useAutodiagnosticsStatisticsQuery } from "graphql/queries/generated/AutodiagnosticsStatistics"
import { useGetPimeoContentLazyQuery } from "graphql/queries/generated/GetPimeoContent"
import { useGetDataOfMostLoggedEventFieldQuery } from "graphql/queries/generated/GetDataOfMostLoggedEventField"

export const PimeoStatisticsPage = () => {
  const { customer } = useSession()
  const [ filterRange, setFilterRange ] = React.useState<MomentRange>()

  const { data: eventLogsData, loading: isEventLogsLoading } = useGetEventLogsQuery({
    variables: {
      customerCode: customer.code_name,
      period: getPeriodFromFilterRange(filterRange),
    },
  })
  const [ getContent, { data: mostViewedContent } ] = useGetPimeoContentLazyQuery()

  useGetDataOfMostLoggedEventFieldQuery({
    onCompleted: (data) => {
      const mostViewedContentUuid = data.getDataOfMostLoggedEventField?.data
        ?.find((datum) => datum.key === "uuid")?.value
      fetchContentByUuid(mostViewedContentUuid)
    },
    variables: {
      customerCode: customer.code_name,
      eventName: EventName.ReadContent,
      field: "uuid",
      period: getPeriodFromFilterRange(filterRange),
    },
  })

  const { data: completedCountData } = useGlobalAutodiagnosticsStatisticsQuery({
    variables: {
      period: getPeriodFromFilterRange(filterRange),
    },
  })

  const { data: autodiagsData, loading: areAutodiagsLoading } = useAutodiagnosticsStatisticsQuery({
    variables: {
      limit: 0,
      period: getPeriodFromFilterRange(filterRange),
      skip: 0,
    },
  })

  const completedCount = completedCountData?.globalAutodiagnosticsStatistics.completedCount
  const numberOfAutodiagsCompleted = completedCount !== undefined ? completedCount : 0
  const autodiagStatsList = useMemo(() => getAudtodiagStats(autodiagsData?.autodiagnostics.list), [ autodiagsData ])
  const eventLogs = useMemo(() => groupByEventName(eventLogsData?.getEventLogs), [ eventLogsData ])

  return (
    <Styles.Container>
      <Styles.MetricsContainer>
        <Styles.DateRangePickerCard
          title={t`Period filter`}
          hasReducedPadding
        >
          <LedDatePicker
            isFullWidth
            label={t`Select a period`}
            pickerAlignment="left"
            onValidate={setFilterRange}
            onReset={() => setFilterRange(undefined)}
            placeholder={t`All periods included`}
            value={filterRange}
          />
        </Styles.DateRangePickerCard>

        <PimeoMetric
          title={t`Piméo statistics`}
          sections={[
            { content: LoginMetric(eventLogs[EventName.LogInPimeo]?.count ?? 0), icon: "people" },
            { content: CustomerContentMetric(eventLogs[EventName.GoToMyCompany]?.count ?? 0), icon: "visibility" },
          ]}
          isLoading={isEventLogsLoading}
        />

        <PimeoMetric
          title={t`QVCTheque statistics`}
          sections={[
            { content: ContentMetric(eventLogs[EventName.ReadContent]?.count ?? 0), icon: "people" },
            { content: MostConsultedContentMetrics(mostViewedContent?.getContent.title ?? ""), icon: "visibility" },
          ]}
          isLoading={isEventLogsLoading && !mostViewedContent}
        />

      </Styles.MetricsContainer>
      <AutodiagStatsSection
        totalNumberOfAutodiags={autodiagsData?.autodiagnostics?.count ?? 0}
        numberOfAutodiagsCompleted={numberOfAutodiagsCompleted}
        isLoading={areAutodiagsLoading}
        autoDiagStatsList={autodiagStatsList}
      />
    </Styles.Container>
  )

  function fetchContentByUuid(uuid?: string) {
    if (uuid) {
      getContent({ variables: { uuid } })
    }
  }

  function getPeriodFromFilterRange(range?: MomentRange): { from: string, to: string } | undefined {
    if (!range?.from || !range?.to) {
      return undefined
    }

    return {
      from: range.from.toISOString(),
      to: range.to.toISOString(),
    }
  }

  function getAudtodiagStats(autodiagStats: AutodiagStats[] = []): TableRowData[] {
    return autodiagStats.map((autoDiagStats) => {
      const values = new Map<string, string>([
        [ "name", autoDiagStats.title ],
        [ "averageScore", autoDiagStats.statistics.averageScore?.toString() || "-" ],
        [ "completedCount", autoDiagStats.statistics.completedCount.toString() ],
      ])

      return {
        id: autoDiagStats.id.toString(),
        values,
      }
    })
  }

  function groupByEventName(eventLogs: GetEventLogsQuery["getEventLogs"] = []) {
    return eventLogs.reduce((acc, event) => {
      acc[event.eventName] = event
      return acc
    }, {} as Record<EventName, EventLog>)
  }
}
