import * as React from "react"
import {
  PagedNavigation,
  Select,
  SelectOptionProps, SkeletonWrapper, TableRowData,
  Title,
} from "@humanpredictiveintelligence/myqvt-library"
import { t } from "ttag"

import { CompletedAutodiagsMetric } from "../PimeoMetric"
import business from "config/business"
import { numberOfPages } from "utilities/helpers"
import * as Styles from "./AutodiagStatsSection.styles"

export const AutodiagStatsSection: React.FC<AutodiagStatsSectionProps> = (props) => {
  const [ selectedRow, setSelectedRow ] = React.useState<string | null>(null)
  const [ tablePagination, setTablePagination ] = React.useState({
    currentPage: 1,
    loadingOffset: 0,
    pageSize: business.led.discussions.tablePageSizes[0],
  })

  const discussionsColumnDisplayNames = new Map([
    [ "name", t`Autodiagnostic name` ],
    [ "averageScore", t`Average score` ],
    [ "completedCount", t`Completed` ],
  ])

  return (
    <Styles.Container>
      <Title level="metricCard">
        {t`Autodiagnostics statistics`}
      </Title>
      <SkeletonWrapper isLoading={props.isLoading} width={100} height={20}>
        <CompletedAutodiagsMetric numberOfAutodiagsCompleted={props.numberOfAutodiagsCompleted} />
      </SkeletonWrapper>
      <Styles.AutoDiagStatsTable
        isLoading={props.isLoading}
        withShadow
        skeletonCountRow={tablePagination.pageSize}
        skeletonCountColumn={2}
        skeletonSize="small"
        dataList={props.autoDiagStatsList}
        rowIdsToHighlight={selectedRow ? [ selectedRow ] : []}
        highlightRowColor="#e0ebff"
        onRowClicked={setSelectedRow}
        columns={[
          "name",
          "averageScore",
          "completedCount",
        ]}
        columnDisplayNames={discussionsColumnDisplayNames}
        areActionsDisabled
        selectedIds={selectedRow ? [ selectedRow ] : []}
      />
      <Styles.TablePagination>
        <Select
          options={
            business.led.discussions.tablePageSizes.map(size => ({
              value: size,
              wording: size.toString(),
            }))
          }
          onChange={updateTableSize}
          value={tablePagination.pageSize}
          defaultItem={false}
          disabled={props.isLoading}
        />
        <PagedNavigation
          isLoadingData={props.isLoading}
          page={tablePagination.currentPage}
          numberOfPages={numberOfPages(
            props.totalNumberOfAutodiags,
            tablePagination.pageSize,
          )}
          onNavigate={updateTablePage}
        />
      </Styles.TablePagination>
    </Styles.Container>
  )

  /**
   * Update the table page size
   * @param sizeOption Page size option selected
   */
  function updateTableSize(sizeOption: SelectOptionProps | undefined) {
    if (sizeOption) {
      setTablePagination({
        currentPage: 1,
        loadingOffset: 0,
        pageSize: sizeOption.value as number,
      })
    }
  }

  /**
   * Update the current page of the table
   * @param page Selected page
   * @param pagesCount Total number of pages
   */
  function updateTablePage(page: number, pagesCount: number) {
    if (page === 0 || page === pagesCount + 1) {
      return
    }
    else {
      setTablePagination(previousPagination => ({
        ...previousPagination,
        currentPage: page,
        loadingOffset: previousPagination.pageSize * (page - 1),
      }))
    }
  }
}

export interface AutodiagStatsSectionProps {
  isLoading: boolean,
  totalNumberOfAutodiags: number,
  autoDiagStatsList: TableRowData[],
  numberOfAutodiagsCompleted: number,
}
