import React from "react"

import { FeatureWithTabs, NavigationTab, useNavigationTabs } from "../Navigation"
import { Session, useSession } from "../Session"
import { CustomerLedType, PackageCode } from "models/generated"

export const usePackages = (): UsePackagesReturnType => {
  const { customer, user } = useSession()
  const usersTabs = useNavigationTabs(FeatureWithTabs.Users)
  const speakupTabs = useNavigationTabs(FeatureWithTabs.SpeakUp)
  const ledTabs = useNavigationTabs(FeatureWithTabs.Led)
  const pimeoStatisticsTabs = useNavigationTabs(FeatureWithTabs.PimeoStatistics)

  const hasPackage = (packageCode: PackageCode) =>
    customer?.packages?.some(pack => pack.codeName === packageCode && pack.isEnabled) ?? false

  const isSoutienPsyLedEnabled = hasPackage(PackageCode.Led) && !!customer?.leds?.find(
    led => led.type === CustomerLedType.SoutienPsy,
  )?.phoneContact?.phones?.length

  const getMenuItems = React.useCallback((): PackagePage[] => {
    if (!user) {
      return []
    }

    const packages = getEnabledPackages([
      {
        code: PackageCode.Basic,
        pages: getPagesFromTabs(pimeoStatisticsTabs),
      },
      {
        pages: getPagesFromTabs(usersTabs),
      },
      {
        code: PackageCode.Led,
        pages:  isSoutienPsyLedEnabled ? getPagesFromTabs(ledTabs) : [],
      },
      {
        pages: getPagesFromTabs(speakupTabs),
      },
    ], customer)

    return getPackagesPages(packages, true)
  }, [ customer, isSoutienPsyLedEnabled, ledTabs, pimeoStatisticsTabs, speakupTabs, user, usersTabs ])

  return {
    getMenuItems,
    hasPackage,
  } as UsePackagesReturnType
}

interface UsePackagesReturnType {
  getMenuItems: () => PackagePage[],
  hasPackage: (packageCode: PackageCode) => boolean,
}

export function getEnabledPackages(
  packages: Package[],
  customer: NonNullable<Session["customer"] | undefined>,
): Package[] {
  return packages.reduce((customerPackages, pack) => {
    /** If no code was given to a package we assume it's a default one and enable it
     * If one was given we check if it's enabled for the current customer
     * */
    if (!pack.code) {
      customerPackages.push(pack)
    }
    else {
      const customerPackage = customer?.packages?.find(
        customerPackage => customerPackage.codeName === pack.code && customerPackage.isEnabled,
      )
      const pagesInMenu = pack?.pages?.filter(page => page.withMenuItem) as PageInMenu[]
      if (customerPackage) {
        pagesInMenu?.forEach((page, pageIndex) => {
          pack.pages[pageIndex] = {
            ...page,
            icon:  page.icon || customerPackage.icon,
            // Forcefully disable package received from the backend, currently used for speakup and lea Packages
            isDisabled: page.isDisabled ?? !customerPackage.isEnabled,
            label: customerPackage.label || page.label,
            path: page.path,
          }
        })

        customerPackages.push(pack)
      }
    }

    return customerPackages
  }, Array<Package>())
}

function getPackagesPages(packages: Package[], onlyPagesInMenu?: boolean) {
  if (onlyPagesInMenu) {
    return packages.reduce((pages, package_) => {
      const lastEnablePageIndex = package_.pages.findIndex(page => !page.isDisabled)
      const pagesInMenu = package_.pages.filter(page => page.withMenuItem) as PageInMenu[]

      // If a page is disabled we add it in last position
      // If a page is enabled we add it before the first disabled page
      pagesInMenu.forEach(page => {
        // Replace menu label if forceMenuLabelProps is defined
        if (page.forceMenuLabel) {
          page.label = page.forceMenuLabel
        }
        if (page.isDisabled) {
          pages.push(page)
        }
        else {
          pages.splice(lastEnablePageIndex, 0, page)
        }
      })

      return pages
    }, Array<PageInMenu>())
  }

  return packages.reduce((pages, package_) => {
    return pages.concat(package_.pages)
  }, Array<PackagePage>())
}

function getPagesFromTabs(tabs: NavigationTab[]): PackagePage[] {
  return tabs.map(tab => ({
    ...tab,
    label: tab.title,
  }))
}

export interface Package {
  code?: PackageCode,
  pages: PackagePage[],
}

// The package page is a page that can be displayed in the home page menu
export interface PageInMenu {
  withMenuItem?: boolean,
  icon?: string,
  isIconImage?: boolean,
  label?: string,
  component: React.ComponentType,
  path: string,
  isDisabled?: boolean,
  isUnauthorized?: boolean,
  forceMenuLabel?: string,
}

export interface Page {
  withMenuItem?: false,
  icon?: never,
  isIconImage?: never,
  label?: never,
  component: React.ComponentType,
  path: string,
  isDisabled?: boolean,
  isUnauthorized?: boolean,
  forceMenuLabel?: string,
}

export type PackagePage = Page | PageInMenu
