import styled from "styled-components/macro"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: auto 0;
  min-height: 3rem;
`

export const Column = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  
  &:first-child {
    flex: 1;
    border-right: 1px solid ${({ theme }) => theme.colors.borderDark};
    padding-right: 1rem;
  }
  
  &:last-child {
    flex: 1;
    border-left: 1px solid ${({ theme }) => theme.colors.borderDark};
    padding-left: 1rem;
  }
`
