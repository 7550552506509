/* tslint:disable */ /* eslint-disable */
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The DateTime scalar type represent a Date with Time
   * String representing a date time, @see https://carbon.nesbot.com/docs/#api-instantiation
   */
  DateTime: string;
  Date: any;
  /** The Upload scalar type represent file upload */
  Upload: any;
  /**
   * The DateInterval scalar type represent a Date interval
   * String representing a date interval, @see https://carbon.nesbot.com/docs/#api-interval
   */
  DateInterval: any;
  _FieldSet: any;
  /** The Json scalar type represnet JSON string */
  Json: any;
};

export type Query = {
  admins: Array<User>;
  attributes: Array<Attribute>;
  autodiagnostic: AutodiagnosticWithSubmission;
  autodiagnosticResult: AutodiagnosticResult;
  autodiagnostics: AutodiagnosticCustomerList;
  conversation?: Maybe<Conversation>;
  export_comments: Scalars['String'];
  export_participations: Scalars['String'];
  export_ranking: Scalars['String'];
  export_satisfaction: Scalars['String'];
  export_users: Scalars['String'];
  getAvailableApps?: Maybe<Array<FrontApp>>;
  getCgu: Cgu;
  getContent: Content;
  getContents: ContentList;
  getCustomerByRegistrationCode?: Maybe<Customer>;
  getCustomerFiles: CustomerFileList;
  /**
   * This query will return the data that has the most logged field
   * example: with field:"uuid", the data that was logged the most (identified by the datum uuid) will be returned
   */
  getDataOfMostLoggedEventField?: Maybe<LogData>;
  getEventLogs?: Maybe<Array<EventLog>>;
  getExternalLinkList: Array<ExternalLink>;
  getLedFiles?: Maybe<LedFileQuery>;
  getNews: NewsList;
  getNewsByNanoId: News;
  getPatchnoteList: Array<Patchnote>;
  getPsyAttributeStatistics: Array<PsyAttributeStatistic>;
  getPsyReports: PsyReportList;
  getPsyReportStatistics?: Maybe<PsyReportStatistic>;
  getRecipientConnectionToken?: Maybe<Scalars['String']>;
  /**  Return the available SSO authentication portals for a given customer  */
  getSsoAuthPortals: Array<SsoAuthPortal>;
  getTags: Array<Tags>;
  getUserGroups?: Maybe<UserGroups>;
  getWorkspacesCount?: Maybe<Scalars['Int']>;
  getZendeskChatAuthToken: Scalars['String'];
  globalAutodiagnosticsStatistics: GlobalAutodiagnosticsStatistics;
  hasPackage: Scalars['Boolean'];
  hasUserSoleWorkspace: Scalars['Boolean'];
  languages: Array<Language>;
  logout: Scalars['Boolean'];
  me: User;
  participation?: Maybe<Survey_Participation>;
  permissions: Array<Permission>;
  procedure_import_users_analyse: ProcedureImportUsersAnalyse;
  procedure_import_users_execute: ProcedureImportUsersExecute;
  procedures: Array<Procedures>;
  scheduled_survey?: Maybe<Scheduled_Survey>;
  scheduled_surveys: Array<Scheduled_Survey>;
  send_init_password_to_user: Scalars['Boolean'];
  send_welcome_users?: Maybe<Scalars['Boolean']>;
  statistics_global: Statistics_Global;
  survey: Survey;
  survey_scheduling: Survey_Scheduling;
  theme: Theme;
  themes: Array<Theme>;
  token: Token_User;
  /**  Return the authenticated user or a manager  */
  whoami: AuthenticatedUser;
};


export type QueryAdminsArgs = {
  email?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroups>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryAutodiagnosticArgs = {
  id: Scalars['Int'];
};


export type QueryAutodiagnosticResultArgs = {
  id: Scalars['Int'];
};


export type QueryAutodiagnosticsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryConversationArgs = {
  comment_id: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryExport_CommentsArgs = {
  attribute_value_ids?: InputMaybe<Array<Scalars['Int']>>;
  group_attribute_id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Supported_Language_Code>;
  scheduled_survey_id: Scalars['Int'];
};


export type QueryExport_ParticipationsArgs = {
  attribute_value_ids?: InputMaybe<Array<Scalars['Int']>>;
  group_attribute_id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Supported_Language_Code>;
  scheduled_survey_id: Scalars['Int'];
};


export type QueryExport_RankingArgs = {
  attribute_value_ids?: InputMaybe<Array<Scalars['Int']>>;
  group_attribute_id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Supported_Language_Code>;
  scheduled_survey_id: Scalars['Int'];
};


export type QueryExport_SatisfactionArgs = {
  attribute_value_ids?: InputMaybe<Array<Scalars['Int']>>;
  group_attribute_id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Supported_Language_Code>;
  scheduled_survey_id: Scalars['Int'];
};


export type QueryExport_UsersArgs = {
  language?: InputMaybe<Supported_Language_Code>;
  user_ids?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryGetContentArgs = {
  uuid: Scalars['String'];
};


export type QueryGetContentsArgs = {
  contentTypes?: InputMaybe<Array<ContentType>>;
  excludedUuid?: InputMaybe<Scalars['String']>;
  isBookmark?: InputMaybe<Scalars['Boolean']>;
  isRandomized?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Order>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetCustomerByRegistrationCodeArgs = {
  registrationCode: Scalars['String'];
};


export type QueryGetCustomerFilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDataOfMostLoggedEventFieldArgs = {
  customerCode?: InputMaybe<Scalars['String']>;
  eventName: EventName;
  field: Scalars['String'];
  period?: InputMaybe<DateTimePeriod>;
};


export type QueryGetEventLogsArgs = {
  customerCode?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<DateTimePeriod>;
};


export type QueryGetExternalLinkListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetLedFilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetNewsArgs = {
  excludedNanoId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetNewsByNanoIdArgs = {
  nanoId: Scalars['String'];
};


export type QueryGetPatchnoteListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPsyAttributeStatisticsArgs = {
  dateInterval?: InputMaybe<DateIntervalInput>;
  psyAttributeCodes: Array<Scalars['String']>;
  psyDispositifNanoId: Scalars['String'];
};


export type QueryGetPsyReportsArgs = {
  dateInterval?: InputMaybe<DateIntervalInput>;
  limit?: InputMaybe<Scalars['Int']>;
  psyDispositifNanoId: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPsyReportStatisticsArgs = {
  dateInterval?: InputMaybe<DateIntervalInput>;
  psyDispositifNanoId: Scalars['String'];
};


export type QueryGetSsoAuthPortalsArgs = {
  customerCode: Scalars['String'];
};


export type QueryGetUserGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Order>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryGetWorkspacesCountArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type QueryGlobalAutodiagnosticsStatisticsArgs = {
  period?: InputMaybe<DateTimePeriod>;
};


export type QueryHasPackageArgs = {
  customerCode: Scalars['String'];
  packageCode: Scalars['String'];
};


export type QueryHasUserSoleWorkspaceArgs = {
  email: Scalars['String'];
};


export type QueryProcedure_Import_Users_AnalyseArgs = {
  file: Scalars['Upload'];
};


export type QueryProcedure_Import_Users_ExecuteArgs = {
  import_users_analyse_id: Scalars['Int'];
};


export type QueryProceduresArgs = {
  id?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryScheduled_SurveyArgs = {
  scheduled_survey_id: Scalars['Int'];
};


export type QueryScheduled_SurveysArgs = {
  from_id?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Order>;
  period?: InputMaybe<DateTime_Period>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scheduled_Survey_Status_Timezoned_Filters>;
};


export type QuerySend_Init_Password_To_UserArgs = {
  user_id: Scalars['Int'];
};


export type QuerySurveyArgs = {
  id: Scalars['Int'];
};


export type QuerySurvey_SchedulingArgs = {
  begin_at: Scalars['DateTime'];
  end_at: Scalars['DateTime'];
  survey_id: Scalars['Int'];
};


export type QueryThemeArgs = {
  id: Scalars['Int'];
};


export type QueryThemesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryTokenArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type QueryWhoamiArgs = {
  token: Scalars['String'];
};

export type FilterGroups = {
  userGroups: Array<Scalars['String']>;
  whereType: WhereType;
};

export enum WhereType {
  In = 'in',
  NotIn = 'notIn'
}

export type User = {
  administrator?: Maybe<User>;
  attributes: Array<Attribute>;
  cguAccepted: Scalars['Boolean'];
  choices: Array<Choice>;
  comments: Array<Comment>;
  comments_number: Scalars['Int'];
  current_participation?: Maybe<Survey_Participation>;
  customer: Customer;
  customerCode: Scalars['String'];
  display_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  has_permissions: Scalars['Boolean'];
  id: Scalars['Int'];
  is_admin: Scalars['Boolean'];
  is_fo_user: Scalars['Boolean'];
  language: Language;
  last_name: Scalars['String'];
  lastLoginAt?: Maybe<DateTime_Timezoned>;
  ledProfile?: Maybe<LedProfile>;
  participations: Array<Survey_Participation>;
  permissions: Array<UserPermission>;
  phone_number?: Maybe<Scalars['String']>;
  questions: Array<Question>;
  remember_token?: Maybe<Scalars['String']>;
  scopes: Array<Attribute>;
  settings: User_Settings;
  timezone: Scalars['String'];
  users: RequesterUsers;
  usersTimezones: UsersTimezones;
  utc_offset: Scalars['Int'];
};


export type UserCommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type UserComments_NumberArgs = {
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type UserPermissionsArgs = {
  include_root?: InputMaybe<Scalars['Boolean']>;
};


export type UserUsersArgs = {
  attribute_value_ids?: InputMaybe<Array<Scalars['Int']>>;
  email?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroups>;
  id?: InputMaybe<Scalars['Int']>;
  isPrivileged?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type Attribute = {
  children: Array<Attribute>;
  id: Scalars['Int'];
  is_custom: Scalars['Boolean'];
  is_hierarchy_head: Scalars['Boolean'];
  name: Scalars['String'];
  name_id: Scalars['Int'];
  parent?: Maybe<Attribute>;
  value: Scalars['String'];
};

export type Choice = {
  icon_url: Scalars['String'];
  id: Scalars['Int'];
  id_hpi?: Maybe<Scalars['Int']>;
  illustration_url: Scalars['String'];
  max_satisfaction_value: Scalars['Int'];
  name: Scalars['String'];
  question: Question;
  satisfaction_value: Scalars['Int'];
};

export type Question = {
  choices: Array<Choice>;
  id: Scalars['Int'];
  id_hpi?: Maybe<Scalars['Int']>;
  illustration_url?: Maybe<Scalars['String']>;
  is_killer_question: Scalars['Boolean'];
  name: Scalars['String'];
  response_type: Scalars['String'];
  surveys: Array<Survey>;
  theme?: Maybe<Theme>;
};

export type Survey = {
  description: Scalars['String'];
  id: Scalars['Int'];
  id_hpi?: Maybe<Scalars['Int']>;
  last_used_at?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  next_used_at?: Maybe<Scalars['DateTime']>;
  questions: Array<Question>;
  scheduled_surveys: Array<Scheduled_Survey>;
  theme: Theme;
};

export type Scheduled_Survey = {
  begin_at: DateTime_Timezoned;
  comments: Array<Comment>;
  comments_number: Scalars['Int'];
  creator: User;
  custom_question?: Maybe<Question>;
  detailedStatistics: Statistics;
  end_at: DateTime_Timezoned;
  id: Scalars['Int'];
  isManualPublication: Scalars['Boolean'];
  killer_question: Question;
  next_theme?: Maybe<Theme>;
  number_of_recipient_comments: Scalars['Int'];
  ordered_questions: Array<Question>;
  question_scheduled_surveys: Array<Question_Scheduled_Survey>;
  recipient_comments: Array<Comment>;
  scheduled_reminders: Array<Scheduled_Reminder>;
  scoped_comments: Array<Comment>;
  scoped_comments_number: Scalars['Int'];
  statistics?: Maybe<Statistics_Scheduled_Survey>;
  statistics_available_at?: Maybe<Scalars['DateTime']>;
  status: Scheduled_Survey_Status_Timezoned;
  survey: Survey;
  userGroups: Array<UserGroup>;
};


export type Scheduled_SurveyCommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type Scheduled_SurveyComments_NumberArgs = {
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type Scheduled_SurveyDetailedStatisticsArgs = {
  attribute_ids?: InputMaybe<Array<Scalars['Int']>>;
  userGroupUuids?: InputMaybe<Array<Scalars['String']>>;
};


export type Scheduled_SurveyNumber_Of_Recipient_CommentsArgs = {
  processing_status?: InputMaybe<Array<Processing_Status>>;
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type Scheduled_SurveyOrdered_QuestionsArgs = {
  question_ids?: InputMaybe<Array<Scalars['Int']>>;
};


export type Scheduled_SurveyQuestion_Scheduled_SurveysArgs = {
  question_ids?: InputMaybe<Array<Scalars['Int']>>;
};


export type Scheduled_SurveyRecipient_CommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  processing_status?: InputMaybe<Array<Processing_Status>>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Scheduled_SurveyScoped_CommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type Scheduled_SurveyScoped_Comments_NumberArgs = {
  writtenAs?: InputMaybe<CommentWrittenAs>;
};

/** The DateTime_timezoned type describe a DateTime in different timezone */
export type DateTime_Timezoned = {
  /** The DateTime at the earliest timezone of all concerned users (ex: the earliest Scheduled_survey.begin_at for all recipients of Scheduled_survey) */
  earliest?: Maybe<Scalars['DateTime']>;
  /** The DateTime at the latest timezone of all concerned users (ex: the latest Scheduled_survey.begin_at for all recipients of Scheduled_survey) */
  latest?: Maybe<Scalars['DateTime']>;
  /** The DateTime without timezone apply (for the local timezone) */
  local: Scalars['DateTime'];
};

/** List of available written as */
export enum CommentWrittenAs {
  Admin = 'admin',
  User = 'user'
}

export type Comment = {
  attribute?: Maybe<Attribute>;
  author_hierarchal_attribute_value?: Maybe<Attribute>;
  comment: Scalars['String'];
  comment_translations: Array<Translation>;
  context: Comment_Context;
  creator?: Maybe<User>;
  id: Scalars['Int'];
  is_anonymous: Scalars['Boolean'];
  object: Comment_Object;
  object_id: Scalars['Int'];
  object_type: CommentObjectType;
  parent_comment?: Maybe<Comment>;
  published_at?: Maybe<Scalars['DateTime']>;
  responses: Array<Comment>;
  validated_at?: Maybe<Scalars['DateTime']>;
};

export type Translation = {
  language: Language;
  wording: Scalars['String'];
};

export type Language = {
  code: Scalars['String'];
  localized_label: Scalars['String'];
  translated_label: Scalars['String'];
};

export type Comment_Context = {
  question?: Maybe<Question>;
  response?: Maybe<Response>;
  scheduled_survey?: Maybe<Scheduled_Survey>;
};

export type Response = {
  choice?: Maybe<Choice>;
  comments: Array<Comment>;
  comments_number: Scalars['Int'];
  free_value?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  question: Question;
  scheduled_survey: Scheduled_Survey;
  user: User;
};


export type ResponseCommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type ResponseComments_NumberArgs = {
  writtenAs?: InputMaybe<CommentWrittenAs>;
};

export type Comment_Object = {
  question_scheduled_survey?: Maybe<Question_Scheduled_Survey>;
  response?: Maybe<Response>;
  scheduled_survey?: Maybe<Scheduled_Survey>;
  survey_participation?: Maybe<Survey_Participation>;
  user?: Maybe<User>;
};

export type Question_Scheduled_Survey = {
  comments: Array<Comment>;
  comments_number: Scalars['Int'];
  id: Scalars['Int'];
  question: Question;
  scheduled_survey: Scheduled_Survey;
  scoped_comments: Array<Comment>;
  scoped_comments_number: Scalars['Int'];
};


export type Question_Scheduled_SurveyCommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type Question_Scheduled_SurveyComments_NumberArgs = {
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type Question_Scheduled_SurveyScoped_CommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type Question_Scheduled_SurveyScoped_Comments_NumberArgs = {
  writtenAs?: InputMaybe<CommentWrittenAs>;
};

export type Survey_Participation = {
  begin_at?: Maybe<DateTime_Timezoned>;
  comments: Array<Comment>;
  comments_number: Scalars['Int'];
  end_at?: Maybe<DateTime_Timezoned>;
  id: Scalars['Int'];
  last_question_answered: Scalars['Int'];
  responses: Array<Response>;
  scheduled_survey: Scheduled_Survey;
  sent_at?: Maybe<Scalars['DateTime']>;
  submitted: Scalars['Boolean'];
  terms_of_service_acceptance: Tos_User;
  user: User;
};


export type Survey_ParticipationCommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  writtenAs?: InputMaybe<CommentWrittenAs>;
};


export type Survey_ParticipationComments_NumberArgs = {
  writtenAs?: InputMaybe<CommentWrittenAs>;
};

export type Tos_User = {
  id: Scalars['Int'];
  is_accepted: Scalars['Boolean'];
  is_refused: Scalars['Boolean'];
  terms: Tos;
};

export type Tos = {
  available_at: Scalars['DateTime'];
  id: Scalars['Int'];
  text: Scalars['String'];
};

/** List of valid comment object types */
export enum CommentObjectType {
  QuestionScheduledSurvey = 'question_scheduled_survey',
  Response = 'response',
  ScheduledSurvey = 'scheduled_survey',
  SurveyParticipation = 'survey_participation',
  User = 'user'
}

export type Statistics = {
  adjust_participation_percentage: Scalars['Float'];
  are_participations_above_cluster: Scalars['Boolean'];
  grade?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  participation_submitted_number: Scalars['Int'];
  real_participation_percentage: Scalars['Float'];
  recipient_number: Scalars['Int'];
  recipient_who_accepted_tos_number: Scalars['Int'];
  scheduled_survey_id?: Maybe<Scalars['Int']>;
  statistics_attributes: Array<Statistics_Attribute>;
  statistics_questions?: Maybe<Array<Statistics_Question>>;
  statisticsUserGroup: Array<StatisticsUserGroup>;
  survey_participations_comments: Array<Comment>;
  survey_participations_comments_number: Scalars['Int'];
  survey_participations_comments_treated_percentage: Scalars['Float'];
};


export type StatisticsStatistics_AttributesArgs = {
  attribute_ids?: InputMaybe<Array<Scalars['Int']>>;
};


export type StatisticsStatistics_QuestionsArgs = {
  question_ids?: InputMaybe<Array<Scalars['Int']>>;
};


export type StatisticsStatisticsUserGroupArgs = {
  userGroupUuids?: InputMaybe<Array<Scalars['String']>>;
};


export type StatisticsSurvey_Participations_CommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type Statistics_Attribute = {
  are_recipients_above_cluster: Scalars['Boolean'];
  attribute: Attribute;
  participationSubmittedNumber: Scalars['Int'];
  recipient_number: Scalars['Int'];
};

export type Statistics_Question = {
  grade?: Maybe<Scalars['Float']>;
  hierarchical_organisation_attribute_grades?: Maybe<Array<Attribute_Grade>>;
  negative_response_comments_number: Scalars['Int'];
  number_of_answered: Scalars['Int'];
  number_of_non_skipped: Scalars['Int'];
  number_of_skipped: Scalars['Int'];
  question: Question;
  question_scheduled_survey_id: Scalars['Int'];
  recipient_number: Scalars['Int'];
  response_comments: Array<Comment>;
  response_comments_number: Scalars['Int'];
  response_comments_treated_percentage: Scalars['Float'];
  statistics_choices?: Maybe<Array<Statistics_Choice>>;
  worst_choice?: Maybe<Choice>;
};


export type Statistics_QuestionResponse_CommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type Attribute_Grade = {
  attribute: Attribute;
  grade?: Maybe<Scalars['Float']>;
  is_all_skipped?: Maybe<Scalars['Boolean']>;
  is_cluster_violated: Scalars['Boolean'];
};

export type Statistics_Choice = {
  choice: Choice;
  is_my_choice: Scalars['Boolean'];
  number_of_selected: Scalars['Int'];
  percentage_of_selected: Scalars['Float'];
};

export type StatisticsUserGroup = {
  areRecipientsOverCluster: Scalars['Boolean'];
  participationSubmittedNumber: Scalars['Int'];
  recipientNumber: Scalars['Int'];
  userGroup: StatisticsUserGroupValue;
};

export type StatisticsUserGroupValue = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  uuid: Scalars['String'];
};

export type Theme = {
  background_url?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  description: Scalars['String'];
  end_survey_illustration_url: Scalars['String'];
  gradient?: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['Int'];
  id_hpi?: Maybe<Scalars['Int']>;
  illustration_url?: Maybe<Scalars['String']>;
  last_used_at?: Maybe<Scalars['DateTime']>;
  mail_illustration_url: Scalars['String'];
  name: Scalars['String'];
  next_used_at?: Maybe<Scalars['DateTime']>;
  questions: Array<Question>;
  surveys: Array<Survey>;
};

/** Processing status of comments */
export enum Processing_Status {
  Awaiting = 'awaiting',
  Draft = 'draft',
  Treated = 'treated'
}

export type Scheduled_Reminder = {
  is_editable: Scalars['Boolean'];
  remind_hours_before_end: Scalars['Float'];
};

export type Statistics_Scheduled_Survey = {
  participation_submitted_number?: Maybe<Scalars['Int']>;
  real_participation_percentage?: Maybe<Scalars['Float']>;
  recipient_number?: Maybe<Scalars['Int']>;
  scheduled_survey: Scheduled_Survey;
  statistics_questions?: Maybe<Array<Statistics_Question>>;
};


export type Statistics_Scheduled_SurveyStatistics_QuestionsArgs = {
  question_ids?: InputMaybe<Array<Scalars['Int']>>;
};

export type Scheduled_Survey_Status_Timezoned = {
  absolute: Scheduled_Survey_Status;
  local: Scheduled_Survey_Status;
  utc: Scheduled_Survey_Status;
};

/** Availability status of a Scheduled_survey. */
export enum Scheduled_Survey_Status {
  Done = 'done',
  Ongoing = 'ongoing',
  Todo = 'todo'
}

export type UserGroup = {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  users: Array<User>;
  usersCount?: Maybe<Scalars['Int']>;
  uuid: Scalars['String'];
};

export type Customer = {
  address?: Maybe<Scalars['String']>;
  can_send_email: Scalars['Boolean'];
  can_send_sms: Scalars['Boolean'];
  cluster_size: Scalars['Int'];
  code_name: Scalars['String'];
  company_info?: Maybe<Scalars['String']>;
  contents: Array<Content>;
  created_at: Scalars['DateTime'];
  customerContacts?: Maybe<Array<CustomerContact>>;
  default_language: Language;
  default_timezone: Scalars['String'];
  email_failure_count: Scalars['Int'];
  email_send_wave_delay: Scalars['Int'];
  freemium_creator_email?: Maybe<Scalars['String']>;
  hasSsoEnabled: Scalars['Boolean'];
  id: Scalars['Int'];
  isClusterViolated: Scalars['Boolean'];
  isTestingModeEnabled: Scalars['Boolean'];
  leds: Array<CustomerLed>;
  logo_link?: Maybe<Scalars['String']>;
  max_email_failure?: Maybe<Scalars['Int']>;
  max_email_per_wave: Scalars['Int'];
  max_recipient_number?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  packages: Array<Package>;
  phone_number?: Maybe<Scalars['String']>;
  psyCompany?: Maybe<PsyCompany>;
  recipientsCount: Scalars['Int'];
  registrationCode?: Maybe<Scalars['String']>;
  speakup_content?: Maybe<Array<Scalars['String']>>;
  website_uuid: Scalars['String'];
  zendeskCompanyId?: Maybe<Scalars['String']>;
};


export type CustomerContentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type Content = {
  authors?: Maybe<Array<Author>>;
  content?: Maybe<Scalars['String']>;
  contentType: ContentType;
  description: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isBookmarked: Scalars['Boolean'];
  publishedAt: Scalars['DateTime'];
  tags: Array<Scalars['String']>;
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
  uuid: Scalars['String'];
};

export type Author = {
  description: Scalars['String'];
  id: Scalars['Int'];
  identity: Scalars['String'];
  imageUrl: Scalars['String'];
  nanoId: Scalars['String'];
};

export enum ContentType {
  Audio = 'audio',
  Image = 'image',
  Text = 'text',
  Video = 'video'
}

export type CustomerContact = {
  email: Scalars['String'];
  identity: Scalars['String'];
  imageUrl: Scalars['String'];
  nanoId: Scalars['String'];
};

export type CustomerLed = {
  chatContact: Scalars['Boolean'];
  emailContact: Scalars['Boolean'];
  meetingContact: Scalars['Boolean'];
  phoneContact: PhoneContact;
  type: CustomerLedType;
};

export type PhoneContact = {
  phones?: Maybe<Array<CustomerLedPhone>>;
};

export type CustomerLedPhone = {
  id: Scalars['Int'];
  phone: Scalars['String'];
  title: Scalars['String'];
};

export enum CustomerLedType {
  AssistanceSocial = 'AssistanceSocial',
  Coaching = 'Coaching',
  SoutienPsy = 'SoutienPsy'
}

export type Package = {
  code_name: PackageCode;
  description: Scalars['String'];
  icon: Scalars['String'];
  id: Scalars['Int'];
  isEnabled: Scalars['Boolean'];
  label: Scalars['String'];
};

export enum PackageCode {
  Autodiag = 'autodiag',
  Basic = 'basic',
  CustomerInfo = 'customer_info',
  Lea = 'lea',
  Led = 'led',
  QvctContent = 'qvct_content',
  Speakup = 'speakup'
}

export type PsyCompany = {
  nanoId: Scalars['String'];
  psyDispositifs: Array<PsyDispositif>;
};

export type PsyDispositif = {
  name: Scalars['String'];
  nanoId: Scalars['String'];
};

export type LedProfile = {
  email: Scalars['String'];
  identity: Scalars['String'];
  nanoId: Scalars['String'];
  phoneNumber: Scalars['String'];
  user: User;
};

export type UserPermission = {
  isGranted: Scalars['Boolean'];
  permission: Permission;
};

export type Permission = {
  code: UserPermissionCode;
  group: PermissionGroup;
  is_scopable?: Maybe<Scalars['Boolean']>;
  parents?: Maybe<Array<Permission>>;
  wording: Scalars['String'];
};

/** List of permissions code */
export enum UserPermissionCode {
  BackofficeView = 'backoffice_view',
  CommentFeedbacksWrite = 'comment_feedbacks_write',
  CommentsRead = 'comments_read',
  LocalQuestionFeedbacksPublish = 'local_question_feedbacks_publish',
  LocalQuestionFeedbacksRead = 'local_question_feedbacks_read',
  LocalQuestionFeedbacksWrite = 'local_question_feedbacks_write',
  LocalSurveyFeedbacksPublish = 'local_survey_feedbacks_publish',
  LocalSurveyFeedbacksRead = 'local_survey_feedbacks_read',
  LocalSurveyFeedbacksWrite = 'local_survey_feedbacks_write',
  PermissionsManage = 'permissions_manage',
  QuestionFeedbacksPublish = 'question_feedbacks_publish',
  QuestionFeedbacksRead = 'question_feedbacks_read',
  QuestionFeedbacksWrite = 'question_feedbacks_write',
  RecipientsManage = 'recipients_manage',
  RecipientsRead = 'recipients_read',
  ResultsPublish = 'results_publish',
  ResultsRead = 'results_read',
  ScheduledSurveyManage = 'scheduled_survey_manage',
  SurveyCreate = 'survey_create',
  SurveyFeedbacksPublish = 'survey_feedbacks_publish',
  SurveyFeedbacksRead = 'survey_feedbacks_read',
  SurveyFeedbacksWrite = 'survey_feedbacks_write',
  UserGroupsManage = 'user_groups_manage'
}

export type PermissionGroup = {
  code: Scalars['String'];
  wording: Scalars['String'];
};

export type User_Settings = {
  comment_languages: Array<Language>;
  id: Scalars['Int'];
  tuto_steps?: Maybe<Array<Tuto_Step>>;
};

export type Tuto_Step = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type RequesterUsers = {
  count: Scalars['Int'];
  list: Array<User>;
  userGroups: Array<UserGroup>;
  usersInGroupCount: Scalars['Int'];
};

export type UsersTimezones = {
  are_on_different_timezones: Scalars['Boolean'];
  earliest: Scalars['String'];
  has_different_timezones: Scalars['Boolean'];
  latest: Scalars['String'];
};

export type AutodiagnosticWithSubmission = {
  autodiagnostic: AutodiagnosticCustomer;
  autodiagnosticSubmission?: Maybe<AutodiagnosticSubmission>;
};

export type AutodiagnosticCustomer = {
  autodiagnosticQuestions: Array<AutodiagnosticQuestion>;
  code: Scalars['String'];
  contents?: Maybe<Array<Content>>;
  description: Scalars['String'];
  id: Scalars['Int'];
  isContentEnabled?: Maybe<Scalars['Boolean']>;
  statistics: AutodiagnosticStatistics;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};


export type AutodiagnosticCustomerStatisticsArgs = {
  period?: InputMaybe<DateTimePeriod>;
};

export type AutodiagnosticQuestion = {
  choices: Array<Scalars['Int']>;
  id: Scalars['Int'];
  label: Scalars['String'];
};

/**
 * The DateTime_period input describe a period composed by:
 * - from: DateTime
 * - to: DateTime
 */
export type DateTimePeriod = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type AutodiagnosticStatistics = {
  averageScore?: Maybe<Scalars['Int']>;
  completedCount: Scalars['Int'];
};

export type AutodiagnosticSubmission = {
  autodiagnostic_id?: Maybe<Scalars['Int']>;
  autodiagnosticSubmissionReponses?: Maybe<Array<AutodiagnosticSubmissionResponse>>;
  customer_id?: Maybe<Scalars['Int']>;
  submitted_at?: Maybe<Scalars['Date']>;
};

export type AutodiagnosticSubmissionResponse = {
  question_id: Scalars['Int'];
  value: Scalars['Int'];
};

export type AutodiagnosticResult = {
  autodiagnostic: AutodiagnosticCustomer;
  profil?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

export type AutodiagnosticCustomerList = {
  count: Scalars['Int'];
  list: Array<AutodiagnosticCustomer>;
};

export type Conversation = {
  choice?: Maybe<Choice>;
  comments: Array<Comment>;
  number_of_comments: Scalars['Int'];
  object_id: Scalars['Int'];
  object_type: CommentObjectType;
  question_scheduled_survey?: Maybe<Question_Scheduled_Survey>;
  scheduled_survey?: Maybe<Scheduled_Survey>;
};

/** Available content language code. */
export enum Supported_Language_Code {
  DaDk = 'da_DK',
  DeDe = 'de_DE',
  EnUs = 'en_US',
  EsEs = 'es_ES',
  FiFi = 'fi_FI',
  FrFr = 'fr_FR',
  ItIt = 'it_IT',
  NlNl = 'nl_NL',
  RoRo = 'ro_RO',
  SvSe = 'sv_SE'
}

export type FrontApp = {
  name: FrontAppName;
  url: Scalars['String'];
};

export enum FrontAppName {
  PimeoBo = 'pimeoBo',
  PimeoFo = 'pimeoFo'
}

export type Cgu = {
  id: Scalars['Int'];
  text: Scalars['String'];
};

/** Display order of a list. */
export enum Order {
  Ascending = 'ascending',
  Descending = 'descending'
}

export type ContentList = {
  count: Scalars['Int'];
  list: Array<Content>;
};

export type CustomerFileList = {
  count: Scalars['Int'];
  list: Array<CustomerFile>;
};

export type CustomerFile = {
  contentType?: Maybe<Scalars['String']>;
  nanoId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum EventName {
  CompleteAutodiag = 'complete_autodiag',
  GoToMyCompany = 'go_to_my_company',
  GoToQvcTheque = 'go_to_qvc_theque',
  LogInPimeo = 'log_in_pimeo',
  ReadContent = 'read_content'
}

export type LogData = {
  customerCode: Scalars['String'];
  data?: Maybe<Array<Datum>>;
  eventName: EventName;
};

export type Datum = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type EventLog = {
  count: Scalars['Int'];
  eventName: EventName;
};

export type ExternalLink = {
  description?: Maybe<Scalars['String']>;
  iconUrl: Scalars['String'];
  id: Scalars['Int'];
  isEnabled: Scalars['Boolean'];
  label: Scalars['String'];
  nanoId: Scalars['String'];
  url: Scalars['String'];
};

export type LedFileQuery = {
  count: Scalars['Int'];
  ledFiles: Array<LedFile>;
};

export type LedFile = {
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  nanoId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type NewsList = {
  count: Scalars['Int'];
  list: Array<News>;
};

export type News = {
  authors?: Maybe<Array<Author>>;
  content: Scalars['String'];
  customer: Customer;
  nanoId: Scalars['String'];
  pictureUrl: Scalars['String'];
  publishedAt: Scalars['DateTime'];
  title: Scalars['String'];
};

export type Patchnote = {
  content: Scalars['String'];
  contents: Array<TranslatedWordingType>;
  id: Scalars['Int'];
  publishedAt: Scalars['String'];
  title: Scalars['String'];
  titles: Array<TranslatedWordingType>;
};

export type TranslatedWordingType = {
  language: Scalars['String'];
  wording: Scalars['String'];
};

export type DateIntervalInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type PsyAttributeStatistic = {
  age: AgeGroup;
  label: Scalars['String'];
  psyAttributeValueStatistics: Array<PsyAttributeValueStatistic>;
  psyRecipientsCount: Scalars['Int'];
  psyReportsCount: Scalars['Int'];
};

export enum AgeGroup {
  AgeGroup_25 = 'AGE_GROUP_25',
  AgeGroup_26_35 = 'AGE_GROUP_26_35',
  AgeGroup_36_45 = 'AGE_GROUP_36_45',
  AgeGroup_46_55 = 'AGE_GROUP_46_55',
  AgeGroup_55 = 'AGE_GROUP_55',
  Nc = 'NC'
}

export type PsyAttributeValueStatistic = {
  psyRecipientsCount: Scalars['Int'];
  psyReportsCount: Scalars['Int'];
  value: Scalars['String'];
};

export type PsyReportList = {
  count: Scalars['Int'];
  list: Array<PsyReport>;
};

export type PsyReport = {
  comment: Scalars['String'];
  date: Scalars['String'];
  issue: Scalars['String'];
  nanoId: Scalars['String'];
};

export type PsyReportStatistic = {
  psyRecipientsCount: Scalars['Int'];
  psyReportsByPimeoCount: Scalars['Int'];
  psyReportsCount: Scalars['Int'];
  psyReportStatisticsByPeriod: PsyReportStatisticsList;
};

export type PsyReportStatisticsList = {
  interval: IntervalEnum;
  list: Array<PsyReportStatisticByPeriod>;
};

export enum IntervalEnum {
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export type PsyReportStatisticByPeriod = {
  period: Scalars['DateTime'];
  psyRecipientsCount: Scalars['Int'];
  psyReportsCount: Scalars['Int'];
};

export type SsoAuthPortal = {
  icon?: Maybe<Scalars['String']>;
  identifier: Scalars['String'];
  label?: Maybe<Scalars['String']>;
};

export type Tags = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UserGroups = {
  list: Array<UserGroup>;
  userGroupsCount: Scalars['Int'];
};

export type GlobalAutodiagnosticsStatistics = {
  completedCount: Scalars['Int'];
};

export type ProcedureImportUsersAnalyse = Procedure & {
  creator: User;
  errors?: Maybe<Array<ProcedureError>>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isDone: Scalars['Boolean'];
  isErrored: Scalars['Boolean'];
  isInProgress: Scalars['Boolean'];
  isNotStartedYet: Scalars['Boolean'];
  isTerminated: Scalars['Boolean'];
  step?: Maybe<Scalars['String']>;
};

export type Procedure = {
  creator: User;
  errors?: Maybe<Array<ProcedureError>>;
  id: Scalars['Int'];
  isDone: Scalars['Boolean'];
  isErrored: Scalars['Boolean'];
  isInProgress: Scalars['Boolean'];
  isNotStartedYet: Scalars['Boolean'];
  isTerminated: Scalars['Boolean'];
  step?: Maybe<Scalars['String']>;
};

export type ProcedureError = {
  message: Scalars['String'];
  step?: Maybe<Scalars['String']>;
};

export type ProcedureImportUsersExecute = Procedure & {
  creator: User;
  errors?: Maybe<Array<ProcedureError>>;
  id: Scalars['Int'];
  importUsersAnalyse?: Maybe<ProcedureImportUsersAnalyse>;
  isDone: Scalars['Boolean'];
  isErrored: Scalars['Boolean'];
  isInProgress: Scalars['Boolean'];
  isNotStartedYet: Scalars['Boolean'];
  isTerminated: Scalars['Boolean'];
  step?: Maybe<Scalars['String']>;
};

/**
 * The Pagination input describe a page to reach by:
 * - classic way using the couple limit / skip
 * - or using from_id / limit couple
 */
export type Pagination = {
  fromId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type Procedures = ProcedureImportUsersAnalyse | ProcedureImportUsersExecute;

/**
 * The DateTime_period input describe a period composed by:
 * - ending_from + duration
 * - or ending_to + duration
 * - or ending_from + ending_to
 */
export type DateTime_Period = {
  duration?: InputMaybe<Scalars['DateInterval']>;
  ending_from?: InputMaybe<Scalars['DateTime']>;
  ending_to?: InputMaybe<Scalars['DateTime']>;
};

export type Scheduled_Survey_Status_Timezoned_Filters = {
  absolute?: InputMaybe<Scheduled_Survey_Status>;
  local?: InputMaybe<Scheduled_Survey_Status>;
  utc?: InputMaybe<Scheduled_Survey_Status>;
};

export type Statistics_Global = {
  adjusted_participation_percentage_latest_delta?: Maybe<Scalars['Float']>;
  average_recipient_comments_per_question: Scalars['Float'];
  average_recipient_comments_per_question_delta?: Maybe<Scalars['Float']>;
  first_scheduled_survey?: Maybe<Scheduled_Survey>;
  id: Scalars['String'];
  killer_question_grade_evolution: Array<Killer_Question_Grade_Evolution_Day>;
  latest_scheduled_survey_adjusted_participation_percentage?: Maybe<Scalars['Float']>;
  latest_scheduled_survey_real_participation_percentage?: Maybe<Scalars['Float']>;
  number_of_recipient_comments: Scalars['Int'];
  number_of_recipient_comments_delta: Scalars['Int'];
  number_of_recipients: Scalars['Int'];
  number_of_recipients_who_accepted_tos?: Maybe<Scalars['Int']>;
  number_of_recipients_who_refused_tos?: Maybe<Scalars['Int']>;
  number_of_scheduled_surveys: Scalars['Int'];
  number_of_scheduled_surveys_with_global_comments: Scalars['Int'];
  number_of_scheduled_surveys_without_global_comments: Scalars['Int'];
  number_of_treated_recipient_comment: Scalars['Int'];
  overall_adjusted_participation_percentage: Scalars['Float'];
  overall_real_participation_percentage: Scalars['Float'];
  percentage_treated_recipient_comment: Scalars['Float'];
  real_percentage_latest_delta?: Maybe<Scalars['Float']>;
  recipient_comments: Array<Comment>;
  recipients_updated_at?: Maybe<Scalars['DateTime']>;
  themes_progress: Array<Statistics_Theme_Progess>;
  treated_recipient_comment_delta: Scalars['Float'];
};


export type Statistics_GlobalKiller_Question_Grade_EvolutionArgs = {
  attribute_value_id?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  period?: InputMaybe<DateTime_Period>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type Statistics_GlobalNumber_Of_Recipient_CommentsArgs = {
  processing_status?: InputMaybe<Array<Processing_Status>>;
};


export type Statistics_GlobalRecipient_CommentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  processing_status?: InputMaybe<Array<Processing_Status>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type Killer_Question_Grade_Evolution_Day = {
  day: Scalars['DateTime'];
  grade?: Maybe<Scalars['Float']>;
  included_scheduled_survey: Array<Killer_Question_Grade_Scheduled_Survey>;
};

export type Killer_Question_Grade_Scheduled_Survey = {
  grade?: Maybe<Scalars['Float']>;
  scheduled_survey: Scheduled_Survey;
  survey: Survey;
};

export type Statistics_Theme_Progess = {
  grade?: Maybe<Scalars['Float']>;
  number_of_scheduled_surveys: Scalars['Int'];
  number_of_surveys: Scalars['Int'];
  number_of_used_surveys: Scalars['Int'];
  theme: Theme;
};

export type Survey_Scheduling = {
  begin_at: DateTime_Timezoned;
  end_at: DateTime_Timezoned;
  status: Scheduled_Survey_Status_Timezoned;
  survey_id: Scalars['Int'];
};

export type Token_User = {
  token: Scalars['String'];
  user: User;
};

export type AuthenticatedUser = {
  customerCode: Scalars['String'];
  id: Scalars['Int'];
  type: UserType;
};

export enum UserType {
  ComRhAdmin = 'ComRHAdmin',
  Manager = 'Manager',
  User = 'User'
}

export type Mutation = {
  accept_tos: Tos_User;
  acceptCgu: Scalars['Boolean'];
  add_question_feedback?: Maybe<Comment>;
  add_response_feedback?: Maybe<Comment>;
  add_survey_feedback?: Maybe<Comment>;
  add_survey_participation_comment?: Maybe<Comment>;
  add_survey_participation_feedback?: Maybe<Comment>;
  add_user: User;
  addFreemiumCustomer?: Maybe<Customer>;
  addLedProfile: LedProfile;
  addLog: Log;
  addScheduledSurvey: Scheduled_Survey;
  addUserGroup: UserGroup;
  addUsersToUserGroup: UserGroup;
  /**  Authenticate an user  */
  authenticate: Session;
  authenticateComRHAdmin: Scalars['String'];
  changePassword?: Maybe<Scalars['Boolean']>;
  checkIn: Scalars['Boolean'];
  completeAutodiagnostic?: Maybe<AutodiagnosticSubmission>;
  createMeet: Scalars['Boolean'];
  delete_users: Scalars['Boolean'];
  deleteScheduledSurvey: Scalars['Boolean'];
  deleteUserGroup: Scalars['Boolean'];
  deleteUsersFromUserGroup: UserGroup;
  me: User;
  publish_results: Scalars['Boolean'];
  refuse_tos: Tos_User;
  refuseCgu: Scalars['Boolean'];
  registerAsPimeoUser: User;
  /** Used with a refresh Token to request a renew access token */
  request_new_token?: Maybe<Token_User>;
  request_reset_password?: Maybe<Scalars['Boolean']>;
  /** # Used with a participation code sent through mail to recipient */
  request_token?: Maybe<Token_User>;
  /**  Request an unique authentication link to authenticate an user through SSO to a given authentication portal  */
  requestSsoAuthPortalAuthentication: Scalars['String'];
  resetPassword?: Maybe<Scalars['Boolean']>;
  respond: Response;
  scheduled_survey: Scheduled_Survey;
  set_settings: User_Settings;
  set_user_permissions: Array<UserPermission>;
  set_user_scopes: Array<Attribute>;
  setupRecipientPassword: User;
  startAutodiagnostic?: Maybe<Scalars['Boolean']>;
  toggleMediaContentBookmark: Scalars['Boolean'];
  update_question_feedback?: Maybe<Comment>;
  update_response_feedback?: Maybe<Comment>;
  update_survey_feedback?: Maybe<Comment>;
  update_survey_participation_comment?: Maybe<Comment>;
  update_survey_participation_feedback?: Maybe<Comment>;
  update_tutorial_progression: Scalars['String'];
  updateLedProfile: LedProfile;
  updateUserGroup: UserGroup;
  user: User;
};


export type MutationAccept_TosArgs = {
  tos_id: Scalars['Int'];
};


export type MutationAcceptCguArgs = {
  cguId: Scalars['Int'];
};


export type MutationAdd_Question_FeedbackArgs = {
  attributeValueId?: InputMaybe<Scalars['Int']>;
  comment: CommentInput;
  questionId: Scalars['Int'];
};


export type MutationAdd_Response_FeedbackArgs = {
  comment: CommentInput;
  responseId: Scalars['Int'];
};


export type MutationAdd_Survey_FeedbackArgs = {
  attributeValueId?: InputMaybe<Scalars['Int']>;
  comment: CommentInput;
  scheduledSurveyId: Scalars['Int'];
};


export type MutationAdd_Survey_Participation_CommentArgs = {
  comment: Scalars['String'];
  is_anonymous?: InputMaybe<Scalars['Boolean']>;
  object_id: Scalars['Int'];
};


export type MutationAdd_Survey_Participation_FeedbackArgs = {
  comment: CommentInput;
  surveyParticipationId: Scalars['Int'];
};


export type MutationAdd_UserArgs = {
  attributeIds?: InputMaybe<Array<Scalars['Int']>>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  permissions?: InputMaybe<Array<UserPermissionInput>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  scopes?: InputMaybe<Array<Scalars['Int']>>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationAddFreemiumCustomerArgs = {
  customerName: Scalars['String'];
  defaultLanguage: Scalars['String'];
  defaultTimezone: Scalars['String'];
  freemiumEmail: Scalars['String'];
  freemiumFirstName: Scalars['String'];
  freemiumLastName: Scalars['String'];
  freemiumPassword: Scalars['String'];
};


export type MutationAddLedProfileArgs = {
  email: Scalars['String'];
  identity: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationAddLogArgs = {
  customerCode: Scalars['String'];
  data?: InputMaybe<Array<DatumInput>>;
  eventName: EventName;
  sessionId: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationAddScheduledSurveyArgs = {
  begin_at: Scalars['DateTime'];
  end_at: Scalars['DateTime'];
  isManualPublication: Scalars['Boolean'];
  scheduled_reminders?: InputMaybe<Array<Scalars['Float']>>;
  survey_id: Scalars['Int'];
  userGroupUuids: Array<Scalars['String']>;
};


export type MutationAddUserGroupArgs = {
  name: Scalars['String'];
};


export type MutationAddUsersToUserGroupArgs = {
  userGroupUuid: Scalars['String'];
  userIds: Array<Scalars['Int']>;
};


export type MutationAuthenticateArgs = {
  customerCode?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAuthenticateComRhAdminArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationCompleteAutodiagnosticArgs = {
  code: Scalars['String'];
  responses?: InputMaybe<Array<AutodiagnosticSubmissionResponseInput>>;
};


export type MutationCreateMeetArgs = {
  availabilitySlot1?: InputMaybe<MeetAvailabilitySlot>;
  availabilitySlot2?: InputMaybe<MeetAvailabilitySlot>;
  date1?: InputMaybe<Scalars['DateTime']>;
  date2?: InputMaybe<Scalars['DateTime']>;
  ledType: CustomerLedType;
  objectMessage?: InputMaybe<Scalars['String']>;
  requestType: MeetRequestType;
  textMessage?: InputMaybe<Scalars['String']>;
};


export type MutationDelete_UsersArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteScheduledSurveyArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserGroupArgs = {
  uuid: Scalars['String'];
};


export type MutationDeleteUsersFromUserGroupArgs = {
  userGroupUuid: Scalars['String'];
  userIds: Array<Scalars['Int']>;
};


export type MutationMeArgs = {
  language?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


export type MutationPublish_ResultsArgs = {
  availableAt?: InputMaybe<Scalars['DateTime']>;
  scheduledSurveyId: Scalars['Int'];
};


export type MutationRefuse_TosArgs = {
  tos_id: Scalars['Int'];
};


export type MutationRefuseCguArgs = {
  cguId: Scalars['Int'];
};


export type MutationRegisterAsPimeoUserArgs = {
  email: Scalars['String'];
};


export type MutationRequest_New_TokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRequest_Reset_PasswordArgs = {
  email: Scalars['String'];
};


export type MutationRequest_TokenArgs = {
  participationCode?: InputMaybe<Scalars['String']>;
};


export type MutationRequestSsoAuthPortalAuthenticationArgs = {
  authPortalIdentifier: Scalars['String'];
  sourceAppCode: AllowedApplications;
};


export type MutationResetPasswordArgs = {
  actionToken: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationRespondArgs = {
  choice_id?: InputMaybe<Scalars['Int']>;
  comment_text?: InputMaybe<Scalars['String']>;
  free_value?: InputMaybe<Scalars['String']>;
  is_comment_anonymous?: InputMaybe<Scalars['Boolean']>;
  question_id: Scalars['Int'];
  scheduled_survey_id: Scalars['Int'];
};


export type MutationScheduled_SurveyArgs = {
  begin_at?: InputMaybe<Scalars['DateTime']>;
  end_at?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isManualPublication?: InputMaybe<Scalars['Boolean']>;
  scheduled_reminders?: InputMaybe<Array<Scalars['Float']>>;
  statistics_available_at?: InputMaybe<Scalars['DateTime']>;
  survey_id?: InputMaybe<Scalars['Int']>;
  userGroupUuids?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationSet_SettingsArgs = {
  settings: Array<User_Setting>;
};


export type MutationSet_User_PermissionsArgs = {
  permissions: Array<UserPermissionInput>;
  user_id: Scalars['Int'];
};


export type MutationSet_User_ScopesArgs = {
  scopes: Array<Scalars['Int']>;
  user_id: Scalars['Int'];
};


export type MutationSetupRecipientPasswordArgs = {
  email: Scalars['String'];
  jwt: Scalars['String'];
  password: Scalars['String'];
};


export type MutationStartAutodiagnosticArgs = {
  code: Scalars['String'];
};


export type MutationToggleMediaContentBookmarkArgs = {
  contentUuid: Scalars['String'];
};


export type MutationUpdate_Question_FeedbackArgs = {
  comment: CommentInput;
  id: Scalars['Int'];
};


export type MutationUpdate_Response_FeedbackArgs = {
  comment: CommentInput;
  id: Scalars['Int'];
};


export type MutationUpdate_Survey_FeedbackArgs = {
  comment: CommentInput;
  id: Scalars['Int'];
};


export type MutationUpdate_Survey_Participation_CommentArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  is_anonymous?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdate_Survey_Participation_FeedbackArgs = {
  comment: CommentInput;
  id: Scalars['Int'];
};


export type MutationUpdate_Tutorial_ProgressionArgs = {
  name: Scalars['String'];
  value: Scalars['String'];
};


export type MutationUpdateLedProfileArgs = {
  email?: InputMaybe<Scalars['String']>;
  identity?: InputMaybe<Scalars['String']>;
  nanoId: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserGroupArgs = {
  name: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationUserArgs = {
  attributeIds?: InputMaybe<Array<Scalars['Int']>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<UserPermissionInput>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  scopes?: InputMaybe<Array<Scalars['Int']>>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type CommentInput = {
  isDraft?: InputMaybe<Scalars['Boolean']>;
  parentCommentId?: InputMaybe<Scalars['Int']>;
  translations: Array<Comment_Translation>;
};

export type Comment_Translation = {
  comment?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
};

export type UserPermissionInput = {
  code: UserPermissionCode;
  isGranted: Scalars['Boolean'];
};

export type DatumInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Log = {
  createdAt: Scalars['DateTime'];
  customerCode: Scalars['String'];
  data?: Maybe<Array<Datum>>;
  eventName: EventName;
  sessionId: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type Session = {
  token: Scalars['String'];
  user: AuthenticatedUser;
};

export type AutodiagnosticSubmissionResponseInput = {
  question_id: Scalars['Int'];
  value: Scalars['Int'];
};

export enum MeetAvailabilitySlot {
  Afternoon = 'afternoon',
  Evening = 'evening',
  Morning = 'morning'
}

export enum MeetRequestType {
  ChatMeet = 'chat_meet',
  EmailMeet = 'email_meet',
  PhoneMeet = 'phone_meet',
  VideoMeet = 'video_meet'
}

export enum AllowedApplications {
  PimeoBo = 'pimeoBo',
  PimeoFo = 'pimeoFo',
  Speakup = 'speakup'
}

export type User_Setting = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type Attribute_Value = {
  attribute?: Maybe<Attribute>;
  children: Array<Attribute_Value>;
  id: Scalars['Int'];
  parent?: Maybe<Attribute_Value>;
  value: Scalars['String'];
};

export type Customer_To_Create = {
  default_language: Scalars['String'];
  default_timezone: Scalars['String'];
  name: Scalars['String'];
};

export enum Gender {
  Man = 'MAN',
  Nc = 'NC',
  Woman = 'WOMAN'
}

/** OneShot */
export enum One_Shot_Available {
  Example = 'example'
}

/** List of available scopes in the application */
export enum Scopes {
  CanManage = 'can_manage',
  CanParticipate = 'can_participate',
  CanSupervise = 'can_supervise'
}

export enum Seniority {
  Nc = 'NC',
  Seniority_10 = 'SENIORITY_10',
  Seniority_2 = 'SENIORITY_2',
  Seniority_3_5 = 'SENIORITY_3_5',
  Seniority_6_10 = 'SENIORITY_6_10'
}

export type User_First_Admin = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  password: Scalars['String'];
};
