import styled, { css } from "styled-components/macro"
import { DropdownTrigger, IconButton } from "@humanpredictiveintelligence/myqvt-library"
import { DateTimeRangePickerProps } from "./LedDatePicker"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  gap: 2rem;
  overflow: visible;
`

export const CalendarContainer = styled.div<{ $alignment: DateTimeRangePickerProps["pickerAlignment"] }>`
  ${({ theme, $alignment }) => css`
    position: absolute;
    z-index: 1000;

    margin-top: 16px;
    padding: 24px 32px 16px 32px;

    top: 100%;
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadows.selected};
    border-radius: ${theme.sizes.borderRadius};

    ${$alignment === "left" && css`
      left: 0;
    `}

    ${$alignment === "center" && css`
      left: 50%;
      transform: translateX(-50%);
    `}

    ${$alignment === "right" && css`
      right: 0;
    `}

      // react-day-picker modifiers styling
    .DayPicker-Day {
      padding: 12px;
      transition: all 0.2s ease-in-out;

      &--marked:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)::after {
        content: "";

        position: absolute;
        left: 50%;
        bottom: 4px;
        transform: translateX(-50%);

        display: inline-block;
        width: 5px;
        height: 5px;

        background-color: ${theme.colors.secondary};
        border-radius: ${theme.sizes.borderRadiusRounded};
      }
    }

    // react-day-picker overrides
    .DayPicker {
      &-Month {
        margin: 0;
        flex-wrap: nowrap;

        &:first-child {
          margin-right: 54px;
        }
      }

      &-Months {
        flex-wrap: nowrap;
      }
    }
  `}
`

export const Time = styled.div`
  margin-bottom: 24px;
  padding-top: 16px;

  font-size: ${({ theme }) => theme.typography.fontSizeTextSmaller};
  color: ${({ theme }) => theme.colors.blackMedium};

  border-top: 1px solid ${({ theme }) => theme.colors.blackUltraLight};`

export const PickerActions = styled.div`
  text-align: right;

  & > *:first-child {
    margin-right: 8px;
  }
`

export const FiltersActions = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: calc(${({ theme }) => theme.typography.fontSizeLabelSmaller} + 8px);
`

export const FiltersAction = styled(IconButton)`
  &:not(:last-of-type) {
    margin-right: 16px;
  }
`

export const Dropdown = styled(DropdownTrigger)`
  flex: 1;
  justify-content: stretch;
  position: relative;
`

