/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GlobalAutodiagnosticsStatisticsQueryVariables = Types.Exact<{
  period?: Types.InputMaybe<Types.DateTimePeriod>;
}>;


export type GlobalAutodiagnosticsStatisticsQuery = { globalAutodiagnosticsStatistics: { completedCount: number } };


export const GlobalAutodiagnosticsStatisticsDocument = gql`
    query GlobalAutodiagnosticsStatistics($period: DateTimePeriod) {
  globalAutodiagnosticsStatistics(period: $period) {
    completedCount
  }
}
    `;

/**
 * __useGlobalAutodiagnosticsStatisticsQuery__
 *
 * To run a query within a React component, call `useGlobalAutodiagnosticsStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalAutodiagnosticsStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalAutodiagnosticsStatisticsQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGlobalAutodiagnosticsStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<GlobalAutodiagnosticsStatisticsQuery, GlobalAutodiagnosticsStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalAutodiagnosticsStatisticsQuery, GlobalAutodiagnosticsStatisticsQueryVariables>(GlobalAutodiagnosticsStatisticsDocument, options);
      }
export function useGlobalAutodiagnosticsStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalAutodiagnosticsStatisticsQuery, GlobalAutodiagnosticsStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalAutodiagnosticsStatisticsQuery, GlobalAutodiagnosticsStatisticsQueryVariables>(GlobalAutodiagnosticsStatisticsDocument, options);
        }
export type GlobalAutodiagnosticsStatisticsQueryHookResult = ReturnType<typeof useGlobalAutodiagnosticsStatisticsQuery>;
export type GlobalAutodiagnosticsStatisticsLazyQueryHookResult = ReturnType<typeof useGlobalAutodiagnosticsStatisticsLazyQuery>;
export type GlobalAutodiagnosticsStatisticsQueryResult = Apollo.QueryResult<GlobalAutodiagnosticsStatisticsQuery, GlobalAutodiagnosticsStatisticsQueryVariables>;