import styled from "styled-components/macro"
import { MetricsCard } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  padding: 40px;
`

export const MetricsContainer = styled.div`
  display: flex;
  height: 146px;
  gap: 16px;
  
  & > div {
    flex: 1;
  }
`

export const DateRangePickerCard = styled(MetricsCard)`
    overflow: visible;
`
