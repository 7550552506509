import styled from "styled-components/macro"
import { msgid, ngettext, t } from "ttag"
import React from "react"
import { useTheme } from "styled-components"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"

const Highlighted = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 0.20rem;
`

const Title = styled.div`
  display: inline;
  font-size: ${({ theme }) => theme.typography.fontSizeTextMedium};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`

const Paragraph = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  white-space: break-spaces;
`

export const LoginMetric = (numberOfLogins: number) => <Paragraph>
  <Highlighted>{numberOfLogins}</Highlighted>
  {ngettext(msgid`connection to Piméo`, `connections to Piméo`, numberOfLogins)}
</Paragraph>

export const CustomerContentMetric = (numberOfAccess: number) => <Paragraph>
  <Highlighted>{numberOfAccess}</Highlighted>
  {ngettext(
    msgid`consultation of 'My organization & QWL' page`, `consultations of 'My organization & QWL' page`,
    numberOfAccess,
  )}
</Paragraph>

export const ContentMetric = (numberOfAccess: number) => <Paragraph>
  <Highlighted>{numberOfAccess}</Highlighted>
  {ngettext(msgid`content consulted`, `contents consulted`, numberOfAccess)}
</Paragraph>

export const MostConsultedContentMetrics = (content?: string) => <div>
  <Title>{t`Most consulted content`}</Title>
  <Paragraph>{content || t`No content were consulted`}</Paragraph>
</div>

export const CompletedAutodiagsMetric: React.FC<{ numberOfAutodiagsCompleted: number }> = (
  { numberOfAutodiagsCompleted },
) => {
  const theme = useTheme()
  return <Paragraph>
    <Icon name="trending_up" color={theme.colors.primary}/>
    <Highlighted>{numberOfAutodiagsCompleted}</Highlighted>
    {ngettext(msgid`autodiagnostic completed`, `autodiagnostics completed`, numberOfAutodiagsCompleted)}
  </Paragraph>
}
