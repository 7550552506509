/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDataOfMostLoggedEventFieldQueryVariables = Types.Exact<{
  field: Types.Scalars['String'];
  eventName: Types.EventName;
  period?: Types.InputMaybe<Types.DateTimePeriod>;
  customerCode?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetDataOfMostLoggedEventFieldQuery = { getDataOfMostLoggedEventField?: { customerCode: string, eventName: Types.EventName, data?: Array<{ key: string, value: string }> | undefined } | undefined };


export const GetDataOfMostLoggedEventFieldDocument = gql`
    query GetDataOfMostLoggedEventField($field: String!, $eventName: EventName!, $period: DateTimePeriod, $customerCode: String) {
  getDataOfMostLoggedEventField(field: $field, eventName: $eventName, period: $period, customerCode: $customerCode) {
    data {
      key
      value
    }
    customerCode
    eventName
  }
}
    `;

/**
 * __useGetDataOfMostLoggedEventFieldQuery__
 *
 * To run a query within a React component, call `useGetDataOfMostLoggedEventFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataOfMostLoggedEventFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataOfMostLoggedEventFieldQuery({
 *   variables: {
 *      field: // value for 'field'
 *      eventName: // value for 'eventName'
 *      period: // value for 'period'
 *      customerCode: // value for 'customerCode'
 *   },
 * });
 */
export function useGetDataOfMostLoggedEventFieldQuery(baseOptions: Apollo.QueryHookOptions<GetDataOfMostLoggedEventFieldQuery, GetDataOfMostLoggedEventFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataOfMostLoggedEventFieldQuery, GetDataOfMostLoggedEventFieldQueryVariables>(GetDataOfMostLoggedEventFieldDocument, options);
      }
export function useGetDataOfMostLoggedEventFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataOfMostLoggedEventFieldQuery, GetDataOfMostLoggedEventFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataOfMostLoggedEventFieldQuery, GetDataOfMostLoggedEventFieldQueryVariables>(GetDataOfMostLoggedEventFieldDocument, options);
        }
export type GetDataOfMostLoggedEventFieldQueryHookResult = ReturnType<typeof useGetDataOfMostLoggedEventFieldQuery>;
export type GetDataOfMostLoggedEventFieldLazyQueryHookResult = ReturnType<typeof useGetDataOfMostLoggedEventFieldLazyQuery>;
export type GetDataOfMostLoggedEventFieldQueryResult = Apollo.QueryResult<GetDataOfMostLoggedEventFieldQuery, GetDataOfMostLoggedEventFieldQueryVariables>;