/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPimeoContentQueryVariables = Types.Exact<{
  uuid: Types.Scalars['String'];
}>;


export type GetPimeoContentQuery = { getContent: { title: string } };


export const GetPimeoContentDocument = gql`
    query GetPimeoContent($uuid: String!) {
  getContent(uuid: $uuid) {
    title
  }
}
    `;

/**
 * __useGetPimeoContentQuery__
 *
 * To run a query within a React component, call `useGetPimeoContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPimeoContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPimeoContentQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetPimeoContentQuery(baseOptions: Apollo.QueryHookOptions<GetPimeoContentQuery, GetPimeoContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPimeoContentQuery, GetPimeoContentQueryVariables>(GetPimeoContentDocument, options);
      }
export function useGetPimeoContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPimeoContentQuery, GetPimeoContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPimeoContentQuery, GetPimeoContentQueryVariables>(GetPimeoContentDocument, options);
        }
export type GetPimeoContentQueryHookResult = ReturnType<typeof useGetPimeoContentQuery>;
export type GetPimeoContentLazyQueryHookResult = ReturnType<typeof useGetPimeoContentLazyQuery>;
export type GetPimeoContentQueryResult = Apollo.QueryResult<GetPimeoContentQuery, GetPimeoContentQueryVariables>;