/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AutodiagnosticsStatisticsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  period?: Types.InputMaybe<Types.DateTimePeriod>;
}>;


export type AutodiagnosticsStatisticsQuery = { autodiagnostics: { count: number, list: Array<{ id: number, title: string, statistics: { completedCount: number, averageScore?: number | undefined } }> } };


export const AutodiagnosticsStatisticsDocument = gql`
    query AutodiagnosticsStatistics($limit: Int, $skip: Int, $period: DateTimePeriod) {
  autodiagnostics(limit: $limit, skip: $skip) {
    count
    list {
      id
      title
      statistics(period: $period) {
        completedCount
        averageScore
      }
    }
  }
}
    `;

/**
 * __useAutodiagnosticsStatisticsQuery__
 *
 * To run a query within a React component, call `useAutodiagnosticsStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutodiagnosticsStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutodiagnosticsStatisticsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useAutodiagnosticsStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<AutodiagnosticsStatisticsQuery, AutodiagnosticsStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutodiagnosticsStatisticsQuery, AutodiagnosticsStatisticsQueryVariables>(AutodiagnosticsStatisticsDocument, options);
      }
export function useAutodiagnosticsStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutodiagnosticsStatisticsQuery, AutodiagnosticsStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutodiagnosticsStatisticsQuery, AutodiagnosticsStatisticsQueryVariables>(AutodiagnosticsStatisticsDocument, options);
        }
export type AutodiagnosticsStatisticsQueryHookResult = ReturnType<typeof useAutodiagnosticsStatisticsQuery>;
export type AutodiagnosticsStatisticsLazyQueryHookResult = ReturnType<typeof useAutodiagnosticsStatisticsLazyQuery>;
export type AutodiagnosticsStatisticsQueryResult = Apollo.QueryResult<AutodiagnosticsStatisticsQuery, AutodiagnosticsStatisticsQueryVariables>;