import {
  Icon,
  MetricsCard,
  SkeletonWrapper,
} from "@humanpredictiveintelligence/myqvt-library"
import React from "react"
import { useTheme } from "styled-components"
import * as Styles from "./PimeoMetric.styles"

export const PimeoMetric: React.FC<PimeoMetricProps> = ({ title, sections, isLoading }) => {
  const theme = useTheme()

  return (
    <MetricsCard
      title={title}
      hasReducedPadding
    >
      <Styles.Container>
        <Styles.Column>
          <Icon name={sections[0].icon} color={theme.colors.primary}/>
          <SkeletonWrapper isLoading={isLoading} width={100} height={20}>{sections[0].content}</SkeletonWrapper>
        </Styles.Column>
        <Styles.Column>
          <Icon name={sections[1].icon} color={theme.colors.primary}/>
          <SkeletonWrapper isLoading={isLoading} width={100} height={20}>{sections[1].content}</ SkeletonWrapper >
        </Styles.Column>
      </Styles.Container>
    </MetricsCard>
  )
}

interface Section {
  icon: string,
  content: JSX.Element,
}

interface PimeoMetricProps {
  title: string,
  sections: [Section, Section],
  isLoading?: boolean,
}
